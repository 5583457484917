import { axios, pickErrorMessage, pickResult } from 'lib/axios.client'
import client from 'lib/client'
import makeFormData from 'lib/makeFormData'
import { X_REQUEST_LOCK } from 'lib/config'
import { prodAlternateClient, handleResponse } from 'virtualAccounts/Virtual.accounts.api.client'
import toQueryString from 'lib/queryString'

const headersConfig = {
  headers: {
    'X-REQUEST-LOCK': X_REQUEST_LOCK,
  },
}

export const fetchAllTransactions = (query) => client.get(`t/transaction/RetrieveAllTransactions`, query)

export const fetchReprocessBilllers = (query) => client.get(`t/transaction/ReprocessBillers`, query)
export const reprocessCashDepositManually = (query) => client.get(`t/transaction/ReprocessCashDepositManually`, query)
export const fetchTransactionDetails = (query) => client.get(`t/transaction/RetrieveTransactionDetails`, query)
export const fetchTransactionsNotApplied = (query) =>
  client.get(`t/transaction/RetrieveTransactionNotAppliedToWallet`, query)
export const fetchTransactionsNotMapped = (query) => client.get(`p/terminal/RetrievePosNotMappedTransactions`, query)
export const fetchTransactionStatuses = () => client.get(`t/transaction/GetSearchStatusList`)
export const fetchAgentTypes = () => client.get(`t/transaction/GetAgentType`)
export const fetchTransactionModes = () => client.get(`t/transaction/GetTransactionMode`)
export const fetchAccoountTypes = () => client.get(`i/agents/GetAccountType`)
export const fetchTransactionTypes = () => client.get(`t/transaction/GetTransactionType`)
export const fetchSearchByOptions = () => client.get(`t/transaction/GetSearchByList`)
export const processTransactionNotApplied = (body) => client(`t/transaction/ProcessTransactionNotAppliedToWallet`, body)
export const processTransactionNotMapped = (body) =>
  client(`p/terminal/ProcessPOSNotMappedTransaction`, null, { query: body })
export const updateTransactionStatus = (body) =>
  client(`t/transaction/UpdateTransactionDetail`, body, { method: 'PATCH' })

export const requeryStatus = (query) => client.get(`t/transaction/RequeryTransaction`, query)
export const bulkRequeryTransactions = (body) => axios.post(`t/transaction/BulkRequery`, makeFormData(body))
export const reverseTransaction = (body) => client(`t/transaction/ReverseTransactions`, body)

export const bulkReversal = (body) => client(`t/transaction/BulkReversal`, makeFormData(body))

export const getTransactionReport = (query) =>
  handleResponse(prodAlternateClient.get(`b/bank/GetFidelityTransactionReport`, query))
export const bulkFundWallet = (body) => axios.post(`t/transaction/BatchWalletCredit`, makeFormData(body))
export const bulkProcessCashDeposit = (body) => axios.post(`t/transaction/BulkReprocessCashDeposit`, makeFormData(body))
export const bulkDebitWallets = (body) => axios.post(`t/transaction/BatchWalletDebit`, makeFormData(body))
export const bulkAutoSwipeTransactions = (body) => axios.post(`t/transaction/BulkAutoSwipe`, makeFormData(body))
export const fetchTransferLimitList = () =>
  axios.get('t/transaction/getTransferLimitList').then(pickResult, pickErrorMessage)
export const postTransferLimit = (body) =>
  axios.post('t/transaction/saveTransferLimit', body).then(pickResult, pickErrorMessage)
export const deleteTransferLimit = (params) =>
  axios.delete('t/transaction/deleteTransferLimit', { params }).then(pickResult, pickErrorMessage)

export const fetchTransactionbiller = () => client.get(`t/biller/GetBillerApiList`)
export const uploadTransactionStatus = (body) =>
  handleResponse(prodAlternateClient(`b/bank/BulkTransactionStatusUpdate`, makeFormData(body), headersConfig))

export const fetchTransactionstatus = (query) =>
  handleResponse(prodAlternateClient.get(`b/bank/GetTransactionStatuses`, query))

export const GetBulkFundBatch = (query) => client.get(`t/transaction/GetBulkFundBatch`, query)
export const GetBulkFundItems = (query) => client.get(`t/transaction/GetBulkFundRequestLogs`, query)

export const bulkFundTransaction = ({ params, body }) =>
  axios
    .post(`t/transaction/UploadNewBulkFundBatch${toQueryString(params)}`, makeFormData(body))
    .then(pickResult, pickErrorMessage)

export const bulkFundApprover1 = (body) => axios.post(`t/transaction/UpdateBulkFundBatchApprover1`, body)
export const bulkFundApprover2 = (body) => axios.post(`t/transaction/UpdateBulkFundBatchApprover2`, body)
export const fetchBulkFundApprovers = () => client.get(`i/account/gateforce/users?pageIndex=1&role=OperationHead`)
export const approvesingleTransactionOnBulkFund = (id, status) =>
  axios.post(`t/transaction/ApproveSingleTransactionFromBulkBatch?id=${id}&status=${status}`)

